import React, { Dispatch } from 'react';
import { Button, Dialog, ToolTip } from 'usebeauty';
import classNames from 'classnames';
import { Action, State } from './CtSecurityContentGroup';
import Di_CreateHmac from './modal/Di_CreateHmac';
import Di_DeleteHmac from './modal/Di_DeleteHmac';
import IncludePrivateInfo from './includePrivateInfo/IncludePrivateInfo';
import './SecurityContentGroup.scss';

interface Props {
  state: State;
  setState: Dispatch<Action>;
  loadHandler: () => void;
}

const SecurityContentGroup = ({ state, setState, loadHandler }: Props) => {
  const { vas } = state;

  return (
    <section id="admin-management-security-content">
      <div className="article-container">
        <section id="admin-manage-security-hmac" className="admin-manage-section">
          <div className="flex-box">
            <div className="header">
              <div className="title">
                <h4 className={classNames({ active: vas.hmac })}>
                  HMAC Key
                  <ToolTip
                    content={
                      <div className="description">
                        <p>HMAC Key는 고객 신원 확인의 보안성을 높이기 위하여</p>
                        <p>암호화 키를 수반하는 보안 코드입니다.</p>
                      </div>
                    }
                  >
                    <i className="header-tooltip" />
                  </ToolTip>
                </h4>
                <p>
                  HMAC Key는 보안 코드 형태이며 인증 시 사용됩니다. 생성된 HMAC Key는 <b>최초 1회만 확인이 가능</b>
                  합니다.
                </p>
              </div>
            </div>
            <div className={classNames('status-label', { active: vas.hmac })}>
              {vas.hmac ? '사용 중' : '사용 안 함'}
            </div>
            <div className="button-group">
              <>
                <Dialog
                  content={({ closeHandler }) => (
                    <Di_CreateHmac
                      state={state}
                      setState={setState}
                      loadHandler={loadHandler}
                      closeHandler={closeHandler}
                    />
                  )}
                >
                  <Button ripple={false} className="submit">
                    {vas.hmac ? '갱신' : '생성'}
                  </Button>
                </Dialog>
                {vas.hmac && (
                  <Dialog
                    content={({ closeHandler }) => (
                      <Di_DeleteHmac setState={setState} loadHandler={loadHandler} closeHandler={closeHandler} />
                    )}
                  >
                    <Button ripple={false} className="negative">
                      삭제
                    </Button>
                  </Dialog>
                )}
              </>
            </div>
          </div>
        </section>
        <IncludePrivateInfo state={state} setState={setState} loadHandler={loadHandler} />
      </div>
    </section>
  );
};

export default SecurityContentGroup;
