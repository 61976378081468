import React, { Dispatch, useState } from 'react';
import { Button, Radio } from 'usebeauty';
import { api, process } from 'common/functions';
import { Alert } from 'common/components';
import { Action, State } from '../CtSecurityContentGroup';
import './IncludePrivateInfo.scss';

interface Props {
  state: State;
  setState: Dispatch<Action>;
  loadHandler: () => void;
}
const IncludePrivateInfo = (props: Props) => {
  const { set } = Alert.Context();
  const { include_private_info } = props.state.vas;
  const [edit, setEdit] = useState(false);

  const disabled = props.state.defaultVas.include_private_info === include_private_info;

  const submitHandler = () => {
    props.setState({ type: 'loading', payload: true });
    process(
      api.reqData({ url: 'configs', data: { include_private_info: String(include_private_info) } }),
      api.put.request,
      api.fullFilled(({ response }) => {
        props.setState({ type: 'loading', payload: false });
        props.loadHandler();
        if (response) {
          set({ success: `인증 결과 전송 설정이 완료되었습니다.` });
        } else {
          set({ fail: `인증 결과 전송 설정에 실패하였습니다.` });
        }
        return response;
      })
    );
  };

  return (
    <section id="admin-manage-security-include-private-info" className="admin-manage-section">
      <div className="flex-box">
        <div className="header">
          <div className="title">
            <h4>인증 결과 전송</h4>
            <p>PostMessage에 인증 결과를 포함합니다. 개인 정보 포함 여부를 선택할 수 있습니다.</p>
          </div>
        </div>
        <div className="status-label active">사용 중</div>
        <div className="button-group">
          <>
            {edit ? (
              <>
                <Button
                  ripple={false}
                  className="cancel"
                  onClick={() => {
                    setEdit(false);
                    props.setState({ type: 'reset' });
                  }}
                >
                  취소
                </Button>
                <Button
                  ripple={false}
                  disabled={disabled}
                  className="submit"
                  onClick={() => {
                    setEdit(false);
                    submitHandler();
                  }}
                >
                  저장
                </Button>
              </>
            ) : (
              <Button ripple={false} className="cancel" onClick={() => setEdit(true)}>
                편집
              </Button>
            )}
          </>
        </div>
      </div>
      <div className="description">
        {edit ? (
          <div className="edit-option">
            <p>개인정보 포함 여부를 설정합니다.</p>
            <Radio.Group
              className="security-include-private-info"
              value={include_private_info}
              onChange={(checked) => props.setState({ type: 'vas', payload: { include_private_info: checked } })}
            >
              <Radio text="개인정보 포함" value>
                <p>이름, 주민등록번호, 주소, 연락처, 생년월일등 개인정보를 포함하여 결과를 전송합니다.</p>
              </Radio>
              <Radio text="개인정보 미포함" value={false}>
                <p>이름, 주민등록번호, 주소, 연락처, 생년월일등 개인정보를 제외하여 결과를 전송합니다.</p>
              </Radio>
            </Radio.Group>
          </div>
        ) : (
          <div className="display-option">
            <h6>{include_private_info ? '개인 정보 포함' : '개인 정보 미포함'}</h6>
            <span>
              이름, 주민등록번호, 주소, 연락처, 생년월일등 개인정보를 {include_private_info ? '포함' : '제외'}하여
              결과를 전송합니다.
            </span>
          </div>
        )}
      </div>
    </section>
  );
};

export default IncludePrivateInfo;
