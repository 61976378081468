import React from 'react';
import classNames from 'classnames';
import { ToolTip } from 'common/components';
import { getReviewResultsId } from 'common/api';
import { NullishGuard } from 'hoc';
import { T_FaceCheck, T_IdCard } from '../../Expand';
import './Face.scss';

interface Props {
  content: NonNullable<T_FaceCheck>;
  idCard: NonNullable<T_IdCard>;
  module: typeof getReviewResultsId.res.module;
  isExpired: boolean;
}

const Face: React.FC<Props> = ({ content, idCard, module, isExpired }) => {
  const { is_same_person, is_live, selfie_image } = content;
  return (
    <div id="reviewer-result-table-expand-content-face-check">
      <ul className="info-section">
        <li className="sub-title">심사 세부정보</li>
        <li>
          <h4>
            얼굴 정보 유사도
            <ToolTip position="bottom left" content="신분증 사진과 셀피 사진을 비교하여 유사도 제공">
              <i />
            </ToolTip>
          </h4>
          <p className={classNames({ warning: is_same_person !== 1 })}>{is_same_person === 1 ? 'High' : 'Low'}</p>
        </li>

        <NullishGuard data={[module?.liveness]}>
          <li>
            <h4>
              얼굴 위변조 검사
              <ToolTip position="bottom left" content="실제 사람 얼굴인지 여부를 검사">
                <i />
              </ToolTip>
            </h4>
            <p className={classNames({ warning: is_live !== 1 })}>{is_live === 1 ? '통과' : '실패'}</p>
          </li>
        </NullishGuard>
      </ul>

      <section className="image-section">
        <div className="image-field">
          <h4 className="sub-title">신분증 사진</h4>
          {isExpired ? (
            <div className="image-expired" />
          ) : (
            <img src={`data:image/jpeg;base64,${idCard.id_crop_image}`} alt="id-card" />
          )}
        </div>

        <div className="image-field">
          <h4 className="sub-title">얼굴 사진</h4>
          {isExpired ? (
            <div className="image-expired" />
          ) : (
            <img src={`data:image/jpeg;base64,${selfie_image}`} alt="id-card" />
          )}
        </div>
      </section>
    </div>
  );
};

export default Face;
