import React, { useContext, useEffect, useReducer } from 'react';
import { Loading } from 'usebeauty';
import { useApiCancel } from 'common/hooks';
import { produce } from 'immer';
import { getConfigs, getSecretsHmac } from 'common/api';
import { ContentContext } from 'layout/admin/content/Content';
import { parseConfigs } from 'common/functions';
import { Config } from 'components/admin/manage/models/model.types';
import SecurityContentGroup from './SecurityContentGroup';

export type Action =
  | { type: 'vas'; payload: AtLeastOne<VAS> }
  | { type: 'defaultVas'; payload: AtLeastOne<VAS> }
  | { type: 'loading'; payload: boolean }
  | { type: 'reset' };
export type VAS = Config.Other.Security;

export interface State {
  vas: VAS;
  defaultVas: VAS;
  loading: boolean;
}

const initialVas: VAS = {
  hmac: false,
  include_private_info: false,
};
const initialState: State = {
  vas: { ...initialVas },
  defaultVas: { ...initialVas },
  loading: false,
};

const reducer = (state: State, action: Action) => {
  return produce(state, (draft) => {
    switch (action.type) {
      case 'loading':
        draft['loading'] = action.payload;
        break;
      case 'vas':
        draft['vas'] = { ...state.vas, ...action.payload };
        break;
      case 'defaultVas':
        draft['defaultVas'] = { ...state.defaultVas, ...action.payload };
        break;
      case 'reset':
        draft['vas'] = { ...state.defaultVas };
        break;
    }
  });
};

const CtSecurityContentGroup: React.FC = () => {
  const { setLoading } = useContext(ContentContext);

  const [state, setState] = useReducer(reducer, initialState);
  useApiCancel(['configs']);
  useApiCancel(['secrets/hmac']);

  const loadHandler = async () => {
    setLoading(true);

    const [configs, secretsHmac] = await Promise.all([getConfigs(), getSecretsHmac()]);
    const configObj = parseConfigs(configs, Object.keys(initialVas) as Array<keyof Configs>);

    const payload = {
      hmac: secretsHmac.data.is_exist,
      include_private_info: configObj.include_private_info,
    };
    setState({ type: 'vas', payload });
    setState({ type: 'defaultVas', payload });

    setState({ type: 'loading', payload: false });
    setLoading(false);
  };

  useEffect(() => {
    loadHandler();
  }, []);

  return (
    <>
      {state.loading && <Loading />}
      <SecurityContentGroup state={state} setState={setState} loadHandler={loadHandler} />
    </>
  );
};

export default CtSecurityContentGroup;
