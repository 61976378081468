import React, { Dispatch } from 'react';
import { Action, State } from '../IdcardContent';
import ServerOcr from './ocr/server/ServerOcr';
import WasmOcr from './ocr/wasm/WasmOcr';
import FaceExtract from './faceextract/FaceExtract';
import './IdcardSetting.scss';

interface Props {
  state: State;
  setState: Dispatch<Action>;
  loadHandler: () => void;
}
const IdcardSetting = ({ state, setState, loadHandler }: Props) => {
  return (
    <div id="admin-manage-idcard-setting" className="admin-manage-idcard-setting">
      <div className="setting-area">
        <div className="setting-title">
          <h3>신분증 인증</h3>
        </div>
        <ServerOcr />
        <WasmOcr state={state} setState={setState} loadHandler={loadHandler} />
        <FaceExtract state={state} setState={setState} loadHandler={loadHandler} />
      </div>
    </div>
  );
};

export default IdcardSetting;
